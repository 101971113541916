export const producstData = [
  {
    id: 1,
    title: "گاو کوچولو",
    imageSrc: "./slider_pics/3.jpg",
    description: "جنس گاو نمد و پارچه",
    price: 100000,
  },
  {
    id: 2,
    title: "شیروو",
    imageSrc: "./slider_pics/2.jpg",
    description: "شیرو بچه خوبیه",
    price: 150000,
  },
  {
    id: 3,
    title: "خرس تنبل",
    imageSrc: "./slider_pics/1.jpg",
    description: "خرس و همیشه خوابه",
    price: 150000,
  },
  {
    id: 4,
    title: "گربالوووو",
    imageSrc: "./slider_pics/4.jpg",
    description: "گربالو همیشه خستس",
    price: 150000,
  },
];
