import React from "react";
import { Button, Image, Space } from "antd";
import { AppstoreOutlined, MailOutlined } from "@ant-design/icons";

const scrollToProducts = () => {
  const section = document.querySelector("#productsSection");
  section?.scrollIntoView({ behavior: "smooth", block: "start" });
};

const CustomHeader = () => {
  return (
    <div style={{ display: "flex", height: "64px" }}>
      <Space direction={"horizontal"}>
        <Image height={50} src={"./slider_pics/2.jpg"} />
        <Button
          type="text"
          icon={<AppstoreOutlined />}
          onClick={scrollToProducts}
        >
          محصولات
        </Button>
        <Button type="text" icon={<MailOutlined />}>
          تماس با ما
        </Button>
      </Space>
    </div>
  );
};

export default CustomHeader;
