import React from "react";
import { Affix, Layout } from "antd";
import "./App.css";
import { InstagramOutlined } from "@ant-design/icons";
import CustomHeader from "./components/CustomHeader";
import FirstSection from "./components/contents/FirstSection";
import Products from "./components/contents/Products";
const { Header, Footer, Content } = Layout;

const App: React.FC = () => {
  return (
    <Layout>
      <Affix offsetTop={0}>
        <Header style={{ backgroundColor: "#F5F5F5" }} title={"گالری شیمه"}>
          <CustomHeader />
        </Header>
      </Affix>
      <Content>
        <div>
          <FirstSection />
          <Products />
        </div>
      </Content>

      <Footer
        className={"mainFooter"}
        style={{
          textAlign: "center",
        }}
      >
        <a href={"https://instagram.com/shime_gallery?igshid=YmMyMTA2M2y="}>
          <InstagramOutlined
            style={{ fontSize: 50, color: "red" }}
            alt={"shime_gallery"}
          />
        </a>
      </Footer>
    </Layout>
  );
};

export default App;
