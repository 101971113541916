import React from "react";
import { Image } from "antd";

const FirstSection = () => {
  return (
    <div style={{ height: "100%", width: "100%", opacity: 0.5 }}>
      <Image
        loading={"lazy"}
        height={"100%"}
        width={"100%"}
        preview={false}
        src={"./slider_pics/3.jpg"}
      ></Image>
    </div>
  );
};

export default FirstSection;
