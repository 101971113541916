import React from "react";
import { Card, Col, Divider, Image, Row, Space } from "antd";
import { producstData } from "../../data/productsData";

function Products() {
  return (
    <div id={"productsSection"}>
      <Card title={"محصولات"}>
        <Row gutter={16}>
          {producstData.map((item) => (
            <Col xl={6} style={{ width: "100%", padding: "8px" }}>
              <Card
                hoverable
                cover={
                  <Image
                    height={300}
                    width={"100%"}
                    src={item.imageSrc}
                    preview={false}
                  />
                }
              >
                <Card.Meta title={item.title} description={item.description} />
                <Divider>قیمت</Divider>
                <Space>
                  <span style={{ fontSize: 24 }}>
                    {new Intl.NumberFormat("fa-IR", {
                      maximumSignificantDigits: 3,
                    }).format(item.price)}
                  </span>
                  <span style={{ fontSize: 24 }}>تومان</span>
                </Space>
              </Card>
            </Col>
          ))}
        </Row>
      </Card>
    </div>
  );
}

export default Products;
